export function iOS() {
  return (
    // To satisfy the node renderer, there needs to be a check for
    // the window or else it will fail
    typeof window !== "undefined" &&
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  );
}
