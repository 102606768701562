import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";
import stars_lake from "../assets/images/stars_lake.jpg";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/SEO";
import { iOS } from "../utils/ios";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  gap: 3rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  width: 80%;
  > h2 {
    font-size: 1.65rem;
  }
`;

const HeadingContainer = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  min-height: calc(100vh - 5.8rem);
  text-align: center;
  align-content: center;
  background-image: url(${stars_lake});
  background-size: cover;
`;

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 80% auto;
  grid-template-areas: ". heading ." ". subtitle .";
  gap: 3rem;
  align-items: center;
`;
const Heading = styled.h2`
  grid-area: heading;
  font-size: 3rem;
  color: var(--white);
`;

const Subtitle = styled.p`
  grid-area: subtitle;
  font-size: 1.25rem;
  color: var(--white);
`;

export default function About({ data }) {
  const members = data.people.nodes;
  const page = data.page.nodes;

  return (
    <>
      <Seo title="About" />
      <Layout>
        <HeadingContainer className={iOS() ? "scroll" : "fixed"}>
          <>
            {page.map((page) => {
              return (
                <AboutContainer key={page.id}>
                  <Heading>{page.title}</Heading>
                  <Subtitle>{page.subtitle}</Subtitle>
                </AboutContainer>
              );
            })}
          </>
        </HeadingContainer>
        <Container>
          <h2>The Team</h2>
          <TeamContainer>
            {members.map((person) => (
              <PersonTile key={person.id} person={person} />
            ))}
          </TeamContainer>
        </Container>
      </Layout>
    </>
  );
}

const TeamContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const PersonStyles = styled.div`
  display: grid;
  @supports not (grid-template-rows: subgrid) {
    --rows: 1fr auto auto;
  }
  grid-template-rows: (--rows, subgrid);
  grid-row: span 3;
  grid-gap: 1rem;
  h2,
  p {
    margin: 0;
  }
`;

const StyledLink = styled(Link)`
  color: var(--blue);
  font-size: 1.15rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  box-shadow: 1px 1px 1rem var(--grey);
`;
function PersonTile({ person }) {
  return (
    <PersonStyles>
      <StyledGatsbyImage
        image={person.image.asset.gatsbyImageData}
        alt={person.name}
        height="250px"
        width="250px"
      />
      <StyledLink to={`${person.slug.current}`}>
        <h3>
          <span>{person.name}</span>
        </h3>
      </StyledLink>
      <p>{person.jobTitle}</p>
    </PersonStyles>
  );
}

export const query = graphql`
  query {
    page: allSanityPageHeader(filter: { page: { eq: "About" } }, limit: 1) {
      nodes {
        id
        title
        subtitle
      }
    }
    people: allSanityPerson {
      nodes {
        id
        name
        jobTitle
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
